<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2>İş Ortakları</h2>

        <div class="staticbox">
          <div class="p70">
            <p>
              18 Eylül 2024 Tarihli Ticari Elektronik İleti Yönetim Sistemi
              Entegratörleri Hakkında Tebliğ Resmî Gazete'de yayımlanarak
              yürürlüğe girdi. Tebliğe şu adresten ulaşabilirsiniz:
              <a
                href="https://www.resmigazete.gov.tr/eskiler/2024/09/20240918-6.htm"
                target="_blank"
                >https://www.resmigazete.gov.tr/eskiler/2024/09/20240918-6.htm</a
              >
            </p>

            <p>
              Tebliğe göre Entegratör; Ticari elektronik ileti gönderiminde,
              alıcılara ait onay ve ret bilgilerinin İYS’ye kaydedilmesi, İYS
              üzerinden onay alınması ve reddetme hakkının kullanılması
              hususlarında hizmet sağlayıcılara hizmet vermek üzere faaliyette
              bulunan Bakanlıkça yetkilendirilmiş şirket olarak tanımlanmıştır.
            </p>

            <p>
              Mevcut iş ortaklarının entegratör yetkisi alarak hizmet vermeye
              devam etmek istemeleri halinde tebliğe göre başvuru yapmaları
              zorunludur. 31 Mart 2025 tarihinden önce başvurularını yapmış ve
              yetkilerini almış olanlar dışında, diğer iş ortaklarının yetkisi
              bu tarih itibariyle sona erecektir.
            </p>

            <p>
              Ayrıntılı bilgi için
              <router-link to="/iletisim" class="blue"
                >bize ulaşabilirsiniz</router-link
              >.
            </p>

            <div class="full mtmb">
              <img src="@/assets/img/icons/hs-03.svg" alt />
            </div>

            <!-- <h2>İş Ortağı Modeli</h2> -->

            <!-- <div class="full mtmb">
              <img src="@/assets/img/hero/is_ortakligi.svg" alt />
            </div> -->

            <!-- <h2 class="mt2">İş Ortağı Yetkilendirme</h2> -->
            <!-- <p>
              Bir iş ortağı ancak bir hizmet sağlayıcı tarafından
              yetkilendirildiğinde, bu hizmet sağlayıcının verisine erişim
              yetkisi kazanır.
            </p>
            <p>
              İş ortağı yetkilendirme işlemi, İYS web sitesi üzerinden kurumsal
              giriş yapılarak gerçekleştirilebilir. Bir hizmet sağlayıcı birden
              çok iş ortağını yetkilendirebilir. Benzer biçimde yetkilendirme,
              veriye erişim iletişim kanalı bazında (mesaj, arama, e-posta ve
              mutabakat servisi) yapılabilir. Ancak her hizmet sağlayıcı
              mutabakat servisi için sadece bir iş ortağı yetkilendirebilir.
            </p> -->
          </div>
          <div class="p70">
            <h2>Tebliğ Uyarınca Yetki Alan Entegratörler</h2>

            <div class="isortaklogo center mb2">
              <a
                v-for="item in integrators.filter((e) => e.yetki)"
                :key="item.id"
                :href="item.link"
                target="_blank"
              >
                <img
                  :src="`${CDN_URL}/assets/img/isortaklari` + item.logo"
                  alt
                />
              </a>
            </div>
          </div>

          <div class="p70">
            <h2>31 Mart 2025 Tarihine Kadar İş Ortaklığı Devam Edecekler</h2>

            <div class="isortaklogo center mb2">
              <a
                v-for="item in integrators.filter((e) => !e.yetki)"
                :key="item.id"
                :href="item.link"
                target="_blank"
              >
                <img
                  :src="`${CDN_URL}/assets/img/isortaklari` + item.logo"
                  alt
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <social />

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";

import Social from "../genel/Social";

export default {
  name: "IsOrtakligi",
  components: {
    Social,
  },

  data() {
    return {
      integrators: [],
      CDN_URL: "https://cdn.iys.org.tr",
    };
  },

  // created() {
  //   const response = {
  //     ok: true,
  //     payload: [
  //       {
  //         id: "01",
  //         marka: "Mysoft",
  //         link: "https://iletiyonetimi.com/",
  //         logo: "/mysoft-logo.svg",
  //         yetki: true,
  //       },
  //       {
  //         id: "02",
  //         marka: "Mobilpark",
  //         link: "https://www.mobilpark.biz/",
  //         logo: "/mobilpark-logo.svg",
  //       },
  //       {
  //         id: "03",
  //         marka: "Uzman CRM",
  //         link: "https://www.uzmancrm.com/",
  //         logo: "/uzmancrm-logo.png",
  //         yetki: true,
  //       },
  //       {
  //         id: "04",
  //         marka: "Insider",
  //         link: "https://www.useinsider.com/",
  //         logo: "/insider-logo.svg",
  //       },
  //       {
  //         id: "05",
  //         marka: "Biotekno",
  //         link: "http://www.biotekno.com.tr/",
  //         logo: "/biotekno-logo.svg",
  //       },
  //       {
  //         id: "06",
  //         marka: "Egebilgi",
  //         link: "http://www.egebilgi.com.tr/",
  //         logo: "/egebilgi-logo.png",
  //       },
  //       {
  //         id: "07",
  //         marka: "Related digital",
  //         link: "https://www.relateddigital.com/tr",
  //         logo: "/related-logo.png",
  //       },
  //       {
  //         id: "08",
  //         marka: "Inbox",
  //         link: "https://useinbox.com/tr",
  //         logo: "/inbox-logo.svg",
  //       },
  //       {
  //         id: "09",
  //         marka: "Turatel",
  //         link: "http://www.turatel.com.tr/",
  //         logo: "/turatel-logo.svg",
  //       },
  //       {
  //         id: "10",
  //         marka: "Mobildev",
  //         link: "https://www.mobildev.com/",
  //         logo: "/mobildev-logo.svg",
  //       },
  //       {
  //         id: "11",
  //         marka: "Uyumsoft",
  //         link: "https://www.uyumsoft.com/",
  //         logo: "/uyumsoft-logo.png",
  //       },
  //       {
  //         id: "12",
  //         marka: "Cronoc Yazılım",
  //         link: "https://cronoc.com.tr/",
  //         logo: "/cronoc-logo.png",
  //       },
  //       {
  //         id: "13",
  //         marka: "Krea Digital",
  //         link: "https://krea.digital/tr/",
  //         logo: "/krea-logo.svg",
  //       },
  //       {
  //         id: "14",
  //         marka: "Tani",
  //         link: "https://www.tani.com.tr/",
  //         logo: "/tani-logo.svg",
  //       },
  //       {
  //         id: "15",
  //         marka: "Codec",
  //         link: "https://codec.com.tr/",
  //         logo: "/codec-logo.png",
  //         yetki: true,
  //       },
  //       {
  //         id: "16",
  //         marka: "RND",
  //         link: "https://www.rnd.com.tr/",
  //         logo: "/rnd-logo.png",
  //       },
  //       {
  //         id: "18",
  //         marka: "Arge 24",
  //         link: "https://www.arge24.com.tr/",
  //         logo: "/arge24-logo.svg",
  //       },
  //       {
  //         id: "20",
  //         marka: "Netgsm",
  //         link: "https://www.netgsm.com.tr/",
  //         logo: "/netgsm-logo.svg",
  //         yetki: true,
  //       },
  //       {
  //         id: "21",
  //         marka: "Dengage",
  //         link: "https://dengage.com/",
  //         logo: "/dengage-logo.svg",
  //       },
  //       {
  //         id: "23",
  //         marka: "Smartmessage",
  //         link: "http://www.smartmessage.com/",
  //         logo: "/smartmessage-logo.svg",
  //       },
  //       {
  //         id: "24",
  //         marka: "Dataport",
  //         link: "https://www.dataport.com.tr/",
  //         logo: "/dataport-logo.svg",
  //       },
  //       {
  //         id: "25",
  //         marka: "Inspark",
  //         link: "https://www.inspark.com/",
  //         logo: "/inspark-logo.svg",
  //       },
  //       {
  //         id: "26",
  //         marka: "Revotas",
  //         link: "https://www.revotas.com/tr/",
  //         logo: "/revotas-logo.svg",
  //       },
  //       {
  //         id: "27",
  //         marka: "Setrow",
  //         link: "https://www.setrow.com/tr/",
  //         logo: "/setrow-logo.svg",
  //       },
  //       {
  //         id: "28",
  //         marka: "Figensoft",
  //         link: "https://www.figensoft.com/",
  //         logo: "/figensoft-logo.svg",
  //       },
  //       {
  //         id: "29",
  //         marka: "Kiva Crm",
  //         link: "https://www.kivacrm.com/tr",
  //         logo: "/kivacrm-logo.svg",
  //       },
  //       {
  //         id: "30",
  //         marka: "Doruknet",
  //         link: "https://www.doruk.net.tr/",
  //         logo: "/doruknet-logo.svg",
  //       },
  //       {
  //         id: "31",
  //         marka: "TTmesaj",
  //         link: "https://www.ttmesaj.com/",
  //         logo: "/ttmesaj-logo.svg",
  //       },
  //       {
  //         id: "32",
  //         marka: "Wicasagroup",
  //         link: "https://www.wicasa.com.tr/",
  //         logo: "/wicasa-logo.svg",
  //       },
  //       {
  //         id: "33",
  //         marka: "Sanal Santral",
  //         link: "https://www.sanalsantral.com.tr/",
  //         logo: "/sanalsantral-logo.svg",
  //       },
  //       {
  //         id: "34",
  //         marka: "Veribase",
  //         link: "http://veribase.com/",
  //         logo: "/veribase-logo.svg",
  //       },
  //       {
  //         id: "35",
  //         marka: "Mutlucell",
  //         link: "https://www.mutlucell.com.tr/",
  //         logo: "/mutlucell-logo.svg",
  //       },
  //       {
  //         id: "36",
  //         marka: "KNS",
  //         link: "https://www.kns.com.tr/",
  //         logo: "/kns-logo.svg",
  //       },
  //       {
  //         id: "39",
  //         marka: "Kobikom",
  //         link: "https://www.kobikom.com.tr/",
  //         logo: "/kobikom-logo.svg",
  //       },
  //       {
  //         id: "40",
  //         marka: "Albil",
  //         link: "https://www.albil.com.tr/",
  //         logo: "/albil-logo.svg",
  //       },
  //       {
  //         id: "42",
  //         marka: "Turk Finansal",
  //         link: "http://www.turkfinansal.com.tr/",
  //         logo: "/turkfinansal-logo.svg",
  //       },
  //       {
  //         id: "43",
  //         marka: "Asist Iletisim",
  //         link: "http://asistiletisim.com/",
  //         logo: "/asistbt-logo.svg",
  //       },
  //       {
  //         id: "45",
  //         marka: "Message 34",
  //         link: "https://message34.com/",
  //         logo: "/message34-logo.png",
  //         yetki: true,
  //       },
  //       {
  //         id: "47",
  //         marka: "Verimor",
  //         link: "https://www.verimor.com.tr/",
  //         logo: "/verimor-logo.svg",
  //       },
  //       {
  //         id: "49",
  //         marka: "Organik",
  //         link: "https://www.organikhaberlesme.com.tr/",
  //         logo: "/organik-logo.png",
  //       },
  //       {
  //         id: "51",
  //         marka: "ECHO CCS",
  //         link: "https://echoccs.com/",
  //         logo: "/echo-logo.svg",
  //       },
  //       {
  //         id: "52",
  //         marka: "Oztek",
  //         link: "http://www.oztekhaberlesme.com.tr/",
  //         logo: "/oztek-logo.svg",
  //       },
  //       {
  //         id: "53",
  //         marka: "Netmera",
  //         link: "https://www.netmera.com/",
  //         logo: "/netmera-logo.svg",
  //       },
  //       {
  //         id: "56",
  //         marka: "Ticimax",
  //         link: "https://www.ticimax.com/",
  //         logo: "/ticimax-logo.svg",
  //       },
  //       {
  //         id: "57",
  //         marka: "Makdos",
  //         link: "https://makdos.com/tr/",
  //         logo: "/makdos-logo.png",
  //       },
  //       {
  //         id: "58",
  //         marka: "IQvia",
  //         link: "https://www.iqvia.com/",
  //         logo: "/iqvia-logo.svg",
  //       },
  //       {
  //         id: "60",
  //         marka: "CRM Akademi",
  //         link: "http://www.crmakademi.net/",
  //         logo: "/crmakademi-logo.svg",
  //       },
  //       {
  //         id: "61",
  //         marka: "Uzman Posta",
  //         link: "https://uzmanposta.com/",
  //         logo: "/uzmanposta-logo.svg",
  //       },
  //       {
  //         id: "62",
  //         marka: "Polisoft",
  //         link: "http://www.polisoft.com.tr/",
  //         logo: "/polisoft-logo.svg",
  //       },
  //       {
  //         id: "65",
  //         marka: "SOFT IS",
  //         link: "https://web.soft.com.tr/",
  //         logo: "/soft-logo.svg",
  //         yetki: true,
  //       },
  //       {
  //         id: "66",
  //         marka: "ASISTEL",
  //         link: "http://www.asistel.com.tr/",
  //         logo: "/asistel-logo.svg",
  //       },
  //       {
  //         id: "67",
  //         marka: "Poli Digital",
  //         link: "https://www.polidijital.com/",
  //         logo: "/poli-logo.png",
  //       },
  //       {
  //         id: "70",
  //         marka: "3G Bilişim",
  //         link: "http://www.3gbilisim.com.tr/",
  //         logo: "/3g-bilisim-logo.svg",
  //       },
  //       {
  //         id: "71",
  //         marka: "Hermes iletişim",
  //         link: "http://www.hermesiletisim.net/",
  //         logo: "/hermes-logo.svg",
  //       },
  //       {
  //         id: "72",
  //         marka: "HMasterPiece",
  //         link: "http://www.ozay.net.tr",
  //         logo: "/ozay-logo.svg",
  //       },
  //       {
  //         id: "74",
  //         marka: "ikarus bilişim",
  //         link: "https://ikarusbilisim.com/",
  //         logo: "/ikarus-logo.svg",
  //       },
  //       {
  //         id: "75",
  //         marka: "Tumveri",
  //         link: "http://www.tumveri.com/",
  //         logo: "/tumveri-logo.svg",
  //       },
  //       {
  //         id: "76",
  //         marka: "Fonzip",
  //         link: "https://fonzip.com/tr",
  //         logo: "/fonzip-logo.svg",
  //       },
  //       {
  //         id: "77",
  //         marka: "Condolife",
  //         link: "https://condolife.co/",
  //         logo: "/condolife-logo.svg",
  //       },
  //       {
  //         id: "78",
  //         marka: "Persona Click",
  //         link: "https://www.personaclick.com/",
  //         logo: "/personaclick-logo.svg",
  //       },
  //       {
  //         id: "79",
  //         marka: "Balaban",
  //         link: "https://balaban.ist/",
  //         logo: "/balaban-logo.svg",
  //       },
  //       {
  //         id: "80",
  //         marka: "Rmira",
  //         link: "http://r-mira.com/",
  //         logo: "/rmira-logo.png",
  //       },
  //       {
  //         id: "81",
  //         marka: "MechSoft",
  //         link: "https://www.mechsoft.com.tr/",
  //         logo: "/mechsoft-logo.svg",
  //       },
  //       {
  //         id: "82",
  //         marka: "Isobil",
  //         link: "https://www.isobil.com.tr/",
  //         logo: "/isobil-logo.svg",
  //       },
  //       {
  //         id: "85",
  //         marka: "SiberTeknoloji",
  //         link: "http://www.siberteknoloji.com.tr/",
  //         logo: "/siberteknoloji-logo.svg",
  //       },
  //       {
  //         id: "86",
  //         marka: "DijiIzin",
  //         link: "https://dijiizin.com/",
  //         logo: "/glr-logo.svg",
  //       },
  //       {
  //         id: "88",
  //         marka: "WDCStudio",
  //         link: "https://www.wdc.studio/",
  //         logo: "/wdc-logo.png",
  //       },
  //       {
  //         id: "89",
  //         marka: "TeknoMart",
  //         link: "http://www.teknomart.com.tr/",
  //         logo: "/teknomart-logo.jpg",
  //       },
  //       {
  //         id: "90",
  //         marka: "Segmentify",
  //         link: "https://www.segmentify.com/",
  //         logo: "/segmentify-logo.svg",
  //       },
  //       {
  //         id: "91",
  //         marka: "Sendheap",
  //         link: "https://www.sendheap.com/",
  //         logo: "/sendheap-logo.svg",
  //       },
  //       {
  //         id: "92",
  //         marka: "Matevise",
  //         link: "https://matevise.com/",
  //         logo: "/matevise-logo.png",
  //         yetki: true,
  //       },
  //       {
  //         id: "94",
  //         marka: "Khilon",
  //         link: "https://khilon.com/",
  //         logo: "/khilon-logo.svg",
  //       },
  //     ],
  //   };

  //   this.integrators = [...response.payload].sort((a, b) => {
  //     if (a.marka?.toLowerCase() < b.marka?.toLowerCase()) {
  //       return -1;
  //     }
  //     if (a.marka?.toLowerCase() > b.marka?.toLowerCase()) {
  //       return 1;
  //     }
  //     return 0;
  //   });

  //   this.$env.CONSOLE.warn("MY INTEGRATORS:", this.integrators);
  // },

  created() {
    this.$store.dispatch("applicant/get_integrators").then((response) => {
      this.integrators = [...response.payload].sort((a, b) => {
        if (a.marka?.toLowerCase() < b.marka?.toLowerCase()) {
          return -1;
        }
        if (a.marka?.toLowerCase() > b.marka?.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.$env.CONSOLE.warn("MY INTEGRATORS:", this.integrators);
    });
  },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");
    },
  },
};
</script>

<style scoped>
.isortaklogo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.isortaklogo img {
  object-fit: contain;
  object-position: center;
  height: 45px;
}

.isortaklogo a {
  display: flex;
  align-items: center;
  width: 21%;
  padding: 15px;
  border-radius: 10px;
  margin: 15px;
  border: 1px solid rgb(245, 245, 245);
}

.isortaklogo a:hover {
  background: #fdfbfa;
  box-shadow: 0 10px 25px -15px #00000040;
  border: 1px solid #fff;
}

ul.list {
  margin: 10px 0px 15px 60px;
  text-align: left;
}

ul.list li {
  display: list-item;
  list-style: disc !important;
  text-align: left;
}
</style>
